<template>
  <div class="project-root-container">
    <div class="project-header-container">
      <span style="font-size: 32px; font-weight: bold">{{ projectName }}</span>
      <div class="project-state-container">
        <Icon type="md-lock" color="red" />
        <span style="margin-left: 10px; font-size: 13px; font-weight: bold"
          >非公开项目</span
        >
      </div>
    </div>

    <div class="project-tab-container">
      <div
        @click="tabChanged(1)"
        :class="[
          currentTab == 1
            ? 'project-tab-item-selected'
            : 'project-tab-item-normal',
        ]"
      >
        基本信息
      </div>
      <div
        @click="tabChanged(2)"
        :class="[
          currentTab == 2
            ? 'project-tab-item-selected'
            : 'project-tab-item-normal',
        ]"
      >
        要约信息
      </div>
      <div
        @click="tabChanged(3)"
        :class="[
          currentTab == 3
            ? 'project-tab-item-selected'
            : 'project-tab-item-normal',
        ]"
      >
        全部附件
      </div>
      <div style="flex: 1"></div>
      <div class="project-task-container" @click="goTask">
        <div class="project-task-point"></div>
        <span style="font-size: 15px; color: #e7d5ad">任务中心</span>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <router-view class="project-router-container" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      projectId: 0,
      projectName: "",
      currentTab: 1,
    };
  },
  mounted() {
    this.projectId = this.$router.currentRoute.params.id;
    this.getProjectInfo();
  },
  beforeRouteUpdate(to, from, next) {
    if (this.projectId != to.params.id) {
      this.projectId = to.params.id;
      this.getProjectInfo();
      this.currentTab = 1;
    }
    next();
  },
  methods: {
    ...mapActions({
      projectGetBasicAction: "projectGetBasic",
    }),
    tabChanged(tab) {
      if (this.currentTab == tab) {
        return;
      }
      this.currentTab = tab;
      switch (this.currentTab) {
        case 1:
          this.$router.push({
            name: "project-base",
            params: {
              id: this.projectId,
            },
          });
          break;
        case 2:
          this.$router.push({
            name: "project-invite",
            params: {
              id: this.projectId,
            },
          });
          break;
        case 3:
          this.$router.push({
            name: "project-accessories",
            params: {
              id: this.projectId,
            },
          });
          break;
      }
    },
    goTask() {
      this.$router.push({
        name: "task-dashboard",
        params: {
          id: this.projectId,
        },
      });
    },
    getProjectInfo() {
      this.projectGetBasicAction({
        employer_id: localStorage.getItem("employer_id"),
        project_id: this.projectId,
      })
        .then((res) => {
          this.projectName = res.name;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
      switch (this.$router.currentRoute.name) {
        case "project-base":
          this.currentTab = 1;
          break;
        case "project-invite":
          this.currentTab = 2;
          break;
        case "project-accessories":
          this.currentTab = 3;
          break;
      }
    },
  },
};
</script>

<style scoped>
.project-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px 50px;
  overflow: hidden;
}
.project-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-state-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  padding: 10px 20px;
  border-radius: 4px;
  margin-left: 20px;
  cursor: pointer;
  user-select: none;
}
.project-tab-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  background-color: #313f4a;
  border-radius: 22px;
}
.project-tab-item-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 112px;
  color: #0a0a0a;
  background-color: #ffffff;
  border-radius: 14px;
  cursor: pointer;
  margin: 0px 30px;
}
.project-tab-item-normal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 112px;
  color: #ffffff;
  cursor: pointer;
  margin: 0px 30px;
}
.project-task-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: 80px;
}
.project-task-point {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #e7d5ad;
  margin-right: 10px;
}
.project-router-container {
  flex: 1;
  overflow: hidden;
}
</style>