var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-root-container"},[_c('div',{staticClass:"project-header-container"},[_c('span',{staticStyle:{"font-size":"32px","font-weight":"bold"}},[_vm._v(_vm._s(_vm.projectName))]),_c('div',{staticClass:"project-state-container"},[_c('Icon',{attrs:{"type":"md-lock","color":"red"}}),_c('span',{staticStyle:{"margin-left":"10px","font-size":"13px","font-weight":"bold"}},[_vm._v("非公开项目")])],1)]),_c('div',{staticClass:"project-tab-container"},[_c('div',{class:[
        _vm.currentTab == 1
          ? 'project-tab-item-selected'
          : 'project-tab-item-normal' ],on:{"click":function($event){return _vm.tabChanged(1)}}},[_vm._v(" 基本信息 ")]),_c('div',{class:[
        _vm.currentTab == 2
          ? 'project-tab-item-selected'
          : 'project-tab-item-normal' ],on:{"click":function($event){return _vm.tabChanged(2)}}},[_vm._v(" 要约信息 ")]),_c('div',{class:[
        _vm.currentTab == 3
          ? 'project-tab-item-selected'
          : 'project-tab-item-normal' ],on:{"click":function($event){return _vm.tabChanged(3)}}},[_vm._v(" 全部附件 ")]),_c('div',{staticStyle:{"flex":"1"}}),_c('div',{staticClass:"project-task-container",on:{"click":_vm.goTask}},[_c('div',{staticClass:"project-task-point"}),_c('span',{staticStyle:{"font-size":"15px","color":"#e7d5ad"}},[_vm._v("任务中心")])])]),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view',{staticClass:"project-router-container"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }